// filters
export const COUNTRIES_LIST = 'filter.list.countries';
export const SOURCE_TYPES_LIST = 'filter.list.source_types';
export const SOURCE_CATEGORIES_LIST = 'filter.list.source_categories';
export const ROLES_LIST = 'filter.list.roles';
export const USER_FILTER_ADD = 'filter.user.add';
export const USER_FILTER_CREATE = 'filter.user.create';
export const USER_FILTER_CLEAR = 'filter.user.clear';
export const USER_FILTER_DELETE = 'filter.user.delete';
export const USER_FILTER_EDIT = 'filter.user.edit';
export const USER_LIST = 'user.list';
export const TEAM_USER_LIST = 'team.user.list';
export const USER_GET = 'user.get';
export const USER_INFO_GET = 'user.info.get';
export const USER_EDIT = 'user.edit';
export const PROFILE_EDIT = 'profile.edit';
export const ADD_RELATED_ACCOUNT = 'add.related.account';
export const DELETE_RELATED_ACCOUNT = 'delete.related.account';
export const LOGIN_AS_USER = 'login.ass.user';
export const GENERATE_QR_CODE = 'generate.qr.code';
export const USER_FILTER_DELETE_FLAG = 'user.filter.delete.flag';
export const DEALS_TYPES = 'deals.types';
export const GET_USER_ROLES = 'get.user.roles';
export const SEND_APP_CODE = 'send.app.code';
export const DISABLED_AUTHENTICATION = 'disabled.authentication';
export const DISABLE_2FA = 'disable.2fa';
export const CHECK_CURRENT_TELEGRAM_NAME = 'check.current.telegram.name';
export const SEND_TELEGRAM_CODE = 'send.telegram.code';
export const VALIDATE_TELEGRAM_CODE = 'validate.telegram.code';
export const UPDATE_TELEGRAM_COUNTER = 'update.telegram.counter';

// lead
export const CHECK_URL = 'leads.check.url';
export const LEAD_CONTACTS_CREATE = 'lead.contacts.create';
export const LEAD_CONTACTS_EDIT = 'lead.contacts.edit';
export const LEAD_CARD_CONTACTS_COUNTER = 'lead.card.contacts.counter';
export const CLEAR_CONTACTS_COUNTER = 'clear.contacts.counter';

// лиды в сторе
export const LEAD_CONTACT_TO_STORE_DATA_ADD = 'lead.contact.store.data.add';
export const LEAD_CONTACT_STORE_DATA_CLEAR = 'lead.contact.store.data.clear';
export const LEAD_CONTACT_STORE_DATA_DELETE = 'lead.contact.store.data.delete';
export const LEAD_CONTACT_STORE_DATA_EDIT = 'lead.contact.store.data.edit';
export const EDIT_FAVORITE_LEAD_CONTACT_STORE_DATA = 'lead.favorite.contact.store.data.edit';
export const LEAD_ADD = 'lead.add';
export const LEAD_EDIT = 'lead.edit';
export const LEAD_EDIT_STATUS = 'lead.edit.status';
export const LEAD_COUNT = 'lead.count';
export const LEAD_LIST = 'lead.list';
export const DOWNLOAD_LEADS_XLS = 'download.leads.xls';
export const DOWNLOAD_LEADS_STAT_XLS = 'download.leads.stat.xls';
export const LEAD_STATUS_LIST = 'lead.status.list';
export const LEAD_STATUS_CHANGE_LIST = 'lead.status.change.list';
export const LEAD_MANAGER_LIST = 'lead.manager.list';
export const TEAM_MANAGER_LIST = 'team.manager.list';
export const LOG_MANAGER_LIST = 'log.manager.list';
export const LEAD_GET = 'lead.get';
export const CHECK_ACCESS_TO_LEAD = 'check.access.to.lead';
export const LEAD_CONTACT_DELETE_FLAG = 'lead.contact.delete.flag';
export const LEAD_CONTACT_DELETE = 'lead.contact.delete';
export const LEAD_CONTACT_INCREMENT_ACTION = 'lead.contact.increment.action';
export const LEAD_MASS_ACTION = 'lead.mass.action';
export const LEAD_CONTACT_VALIDATION = 'lead.contact.validation';
export const LEADS_UPLOAD_FILE = 'leads.upload.file';
export const GET_LEADS_STAT = 'get.leads.stat';
export const CHECK_LEADS_URL = 'check.leads.url';

// leads for deals
export const SEARCH_LEAD = 'search.lead';
export const ATTACH_LEAD_FOR_DEAL = 'attach.lead.for.deal';
export const CREATE_NEW_LEAD = 'create.new.lead';
export const CHECK_BID_LEAD = 'check.bid.lead';

// logs
export const LEAD_HISTORY_GET = 'log.get';

// card
export const LEAD_CARD_GET_CONTACT = 'lead.card.get.contact';

// user
export const LOGIN = 'user.login';
export const LOGOUT = 'user.logout';
export const GET_PROFILE = 'user.profile.get';
export const GET_USER_TOKEN = 'get.user.token';

export const GET_USER_NOTIFICATION_COUNT = 'get.user.notification.count';
export const GET_VERSION = 'get.version';
export const USER_ADD = 'user.add';
export const USER_ADD_SET_ROLE = 'user.add.set.role';
export const ADMIN_LOGIN_AS_USER = 'admin.login.as.user';
export const RETURN_TO_ADMIN = 'return.to.admin';
export const USER_LIST_BY_ROLE = 'user.list.by.role';
export const TELEGRAM_AUTH = 'telegram.auth';
export const CREATE_CUSTOM_PRODUCTS = 'create.custom.products';
export const SEND_MESSAGE = 'send.message';

// geo groups
export const GEO_GROUP_LIST = 'geo.group.list';
export const GEO_GROUP_GET = 'geo.group.get';
export const GEO_GROUP_CREATE = 'geo.group.create';
export const GEO_GROUP_EDIT = 'geo.group.edit';
export const GEO_GROUP_NAME_UNIQUE_CHECK = 'geo.group.name.unique.check';

// teams
export const TEAM_LIST = 'team.list';
export const TEAM_GET = 'team.get';
export const TEAM_CREATE = 'team.create';
export const TEAM_EDIT = 'team.edit';
export const TEAM_NAME_UNIQUE_CHECK = 'team.name.unique.check';
export const TEAM_USER_GET_ACCESS = 'team.user.get.access';
export const TEAM_USER_SAVE_ACCESS = 'team.user.save.access';
export const TEAM_USER_IDS_GET = 'team.user.ids.get';
export const SET_TEAM_EDIT_ACCESS_COUNTER = 'set.team.edit.access.counuter';
export const TEAM_ACCESS_FILTERS_COUNTRY = 'team.access.filters.country';
export const TEAM_ACCESS_FILTERS_GEO_GROUP = 'team.access.filters.geo.group';
export const TEAM_ACCESS_FILTERS_SOURCE_TYPE = 'team.access.filters.source.type';
export const TEAM_ACCESS_FILTERS_SOURCE_CATEGORY = 'team.access.filters.source.category';
export const GET_PARTNER_GROUPS = 'get.partner.groups';

// tags
export const TAG_LIST = 'tag.list';
export const TAG_GET = 'tag.get';
export const TAG_CREATE = 'tag.create';
export const TAG_EDIT = 'tag.edit';
export const TAG_NAME_UNIQUE_CHECK = 'tag.name.unique.check';
export const ATTACH_TAG_FOR_PAYMENT = 'attach.tag.for.payment';
export const DELETE_ATTACHED_TAG = 'delete.attached.tag';

// dashboard
export const DASHBOARD_HISTORY_GET = 'dashboard.history.get';
export const DASHBOARD_CURRENT_GET = 'dashboard.current.get';
export const DASHBOARD_CHART = 'dashboard.charts';
export const DASHBOARD_STATUS_STATS = 'dashboard.status.stats';

// report
export const REPORT_PROGRESS_GET = 'report.progress.get';
export const GET_DEALS_REPORT = 'get.deals.report';
export const GET_COST_ITEMS = 'get.cost.items';
export const GET_DEALS_REPORT_BY_MANAGER = 'get.deals.report.by.manager';
export const DOWNLOAD_REPORT_BY_MANAGER = 'download.report.by.manager';
export const GET_CHART_DATA = 'get.chart.data';

// partners
export const PARTNER_CREATE = 'partner.add';
export const PARTNER_LIST = 'partner.list';
export const PARTNER_CONTACTS_CREATE = 'partner.contacts.create';
export const PARTNER_PRODUCT = 'partner.product';
export const PARTNER_SEARCH_LEAD = 'partner.search.lead';
export const PARTNER_SITE_CREATE = 'partner.lead.create';
export const PARTNER_INFO_GET = 'partner.info.get';
export const GET_DEAL_FORMATS_BY_ID = 'get.deal.formats.by.id';
export const PARTNER_EDIT = 'partner.edit';
export const PARTNER_SITE_LIST = 'partner.site.list';
export const PARTNER_SITE_GET = 'partner.site.get';
export const PARTNER_SITE_UPDATE = 'partner.site.update';
export const PARTNER_SITE_DELETE = 'partner.site.delete';
export const PARTNER_CONTACT_GET = 'partner.contact.get';
export const PARTNER_CONTACT_DELETE = 'partner.contact.delete.flag';
export const PARTNER_CONTACTS_EDIT = 'partner.contacts.edit';
export const PARTNER_CONTACT_VALIDATION = 'partner.contacts.validation';
export const PARTNER_LIST_BY_PRODUCT = 'partner.list.by.product';
export const PARTNER_COUNT_BY_PRODUCT = 'partner.count.by.product';
export const PARTNER_SITE_STATS = 'partner.sites.stats';
export const ATTACH_PARTNERS = 'attach.partners';
export const DETACH_PARTNERS = 'detach.partners';
export const GET_FREE_PARTNERS = 'get.free.partners';
export const GET_MY_PARTNERS = 'get.my.partners';
export const CREATE_PARTNER_GROUP = 'create.partner.group';
export const UPLOAD_PARTNER_COUNTRY = 'upload.partner.country';
export const CHANGE_PARTNER_APPOINTED = 'change.partner.appointed';
export const DELETE_PARTNER_FILE = 'delete.partner.file';

export const PARTNER_LIST_OFFLINE = 'partner.list.offline';
export const PARTNER_COUNT_OFFLINE = 'partner.count.offline';
export const PARTNER_OFFLINE_TYPES = 'partner.offline.types';
export const PARTNER_DOWNLOAD_RS_STATS = 'partner.download.rs.stats';
export const TRANSFER_PARTNERS = 'transfer.partners';

export const CURRENCIES_LIST = 'currencies.list';
export const WALLET_TYPES_LIST = 'wallet.types.list';
export const WALLET_TYPES_LIST_ALL = 'wallet.types.list.all';

export const WALLET_SET_TIPS = 'wallet.set.tips';
export const WALLET_CREATE = 'wallets.create';
export const WALLET_GET = 'wallets.get';
export const WALLETS_LIST = 'wallets.list';
export const SET_CURRENT_CURRENCY = 'set.current.currency';
export const CLEAR_CURRENT_CURRENCY = 'clear.current.currency';

export const PARTNER_GROUP_LIST = 'partner.group.list';
export const PARTNER_GROUP_CREATE = 'partner.group.create';
export const PARTNER_GROUP_SEARCH_SITE = 'partner.group.search.site';
export const PARTNER_GROUP_SEARCH_PARTNER = 'partner.group.search.partner';
export const PARTNER_GROUP_GET_INFO = 'partner.group.get.info';
export const PARTNER_GROUP_EDIT = 'partner.group.edit';

// deals
export const DEAL_CREATE = 'deal.create';
export const DEALS_LIST = 'deals.list';
export const DEALS_LIST_OFFLINE = 'deals.list.offline';
export const DEALS_COUNT_OFFLINE = 'deals.count.offline';

export const DEALS_COUNT = 'deals.count';
export const DEALS_PLACE_TYPES = 'deals.place.types';
export const DOWNLOAD_DEALS_XLS = 'download.deals.xls';
export const DOWNLOAD_OPERATION_STATS = 'download.operation.stats';
export const TRANSFER_DEALS = 'transfer.deals';

// new format deal places
export const GET_DEAL_PLACES = 'get.deal.places';
export const GET_DEAL_PLACE_INFO = 'get.deal.place.info';
export const CREATE_DEAL_PLACE = 'create.deal.place';
export const CREATE_NEW_DEAL_PLACE = 'create.new.deal.place';
export const GET_NEW_DEAL_PLACES = 'get.new.deal.places';
export const UPDATE_NEW_DEAL_PLACE = 'update.new.deal.place';

export const UPDATE_DEAL_PLACE = 'update.deal.place';
export const UPDATE_DEAL_PLACE_ROW = 'update.deal.place.row';
export const DEAL_VALIDATION = 'deal.validation';
export const DEAL_INFO = 'deal.info';
export const CHECK_AVAILABLE_PAYMENT = 'check.available.payment';
export const DEAL_SEARCH_SITE = 'deal.search.site';
export const DEAL_CPM_SEARCH_SITE = 'deal.cpm.search.site';
export const DEAL_UPDATE = 'deal.update';
export const DEAL_PLACE_DELETE = 'deal.place.delete';
export const DEAL_STATUS_LIST = 'deal.status.list';
export const DEAL_DEPARTMENT_LIST = 'deal.department.list';
export const DEAL_UPLOAD_FILE = 'deal.upload.file';
export const DEAL_FILE_DOWNLOAD = 'deal.file.download';
export const GET_DEAL_EXTENSION = 'get.deal.extensionTypesList';
export const GET_DEAL_LABELS = 'get.deal.labels';
export const DEAL_FORM_IS_VALID = 'deal.form.is.valid';
export const DEAL_UPDATE_STATUS = 'deal.update.status';
export const MASS_ADD_DEAL_PLACES = 'mass.add.deal.places';

export const RE_OPEN_DEAL = 're.open.deal';
export const CHANGE_PAYMENT_METHOD = 'change.payment.method';

export const DEAL_DATA_CLEAR = 'deal.data.clear';
export const DEAL_ANALYTICS_CREATE = 'deal.analytics.create';
export const DEAL_ANALYTICS_DELETE = 'deal.analytics.delete';
export const DEAL_ANALYTICS_UPLOAD_FILE = 'deal.analytics.upload.file';

export const DEAL_ANALYTICS_EDIT = 'deal.analytics.edit';
export const DEAL_ANALYTICS_DELETE_SITE = 'deal.analytics.delete.site';
export const DEAL_ANALYTICS_GET = 'deal.analytics.get';
export const DEAL_ANALYTICS_TO_STORE_DATA_ADD = 'deal.analytics.store.data.add';
export const DEAL_ANALYTICS_STORE_DATA_CLEAR = 'deal.analytics.store.data.clear';
export const DEAL_ANALYTICS_STORE_DATA_DELETE = 'deal.analytics.store.data.delete';
export const DEAL_ANALYTICS_STORE_DATA_EDIT = 'deal.analytics.store.data.edit';
export const DEAL_ANALYTICS_DATA_CLEAR = 'deal.analytics.data.clear';
export const DEAL_HISTORY = 'deal.history';
export const DEAL_FORM_VALIDATION = 'deal.form.validation';
export const DEAL_ANALYTICS_VALIDATION = 'deal.analytics.validation';
export const DEAL_VALIDATION_REVIEW = 'deal.validation.review';
export const DEAL_RESULTS = 'deal.results';
export const GET_SPEND = 'get.spend';

export const MACROS_CREATE = 'macros.create';
export const MACROS_LIST = 'macros.list';
export const MACROS_GET = 'macros.get';

export const REDIRECTOR_CREATE = 'redirector.create';
export const REDIRECTOR_LIST = 'redirector.list';
export const REDIRECTOR_GET = 'redirector.get';

// new data
export const CREATE_NEW_SPEND = 'create.new.spend';
export const EDIT_SPEND = 'edit.spend';
export const DELETE_SPEND = 'delete.spend';

// payments
export const CREATE_NEW_PAYMENT = 'create.new.payment';
export const EDIT_PAYMENT_COMMENT = 'edit.payment.comment';
export const GET_PAYMENT_LIST_BY_DEAL_ID = 'get.payment.list.by.deal.id';
export const UPDATE_PAYMENT_STATUS = 'update.payment.status';
export const UPDATE_PAYMENT_STATUSES = 'update.payment.statuses';
export const GET_PAYMENT_INFO = 'get.payment.info';
export const UPDATE_PAYMENT = 'update.payment';
export const PAYMENTS_LIST = 'payments.list';
export const CREATE_NEW_ADJUSTMENTS = 'create.new.adjustments';
export const ADJUSTMENTS_LIST = 'adjustments.list';
export const RETURN_PAYMENT_TO_AUDIT = 'return.payment.to.audit';
export const GET_STATS_FOR_COMMENT = 'get.stats.for.comment';
export const GENERATE_STATS_COMMENT = 'generate.stats.comment';
export const LOCK_PAYMENT = 'lock.payment';
export const EXCHANGE_PAYMENT_COST = 'exchange.payment.cost';
export const TO_BLACK_LIST = 'black.list';
export const GET_BLACK_LIST = 'get.black.list';
export const DELETE_FROM_BLACK_LIST = 'delete.from.black.list';
export const UPDATE_PAYMENT_COST = 'update.payment.cost';

export const PAYMENT_UPLOAD_FILE = 'payment.upload.file';
export const PAYMENT_DELETE_FILE = 'payment.delete.file';
export const PAYMENT_FILE_DOWNLOAD = 'payment.file.download';
export const PAYMENTS_TO_INVOICES = 'payments.to.invoices';
export const PAYMENT_CHANGE_PAYMENT_METHOD = 'payment.change.payment.method';

export const PAYMENT_INVOICES_LIST = 'payment.invoices.list';
export const PAYMENT_INVOICE_DOWNLOAD = 'payment.invoice.download';
export const PAYMENT_INVOICE_COUNT = 'payment.invoice.count';
export const PAYMENT_TARGETS_LIST = 'payment.targets.list';
export const PAYMENT_TARGETS_BY_MANAGER = 'payment.targets.by.manager';
export const SET_DEAL_PAYMENT = 'set.deal.payment';
export const DELETE_DEAL_PAYMENT = 'delete.deal.payment';
export const EDIT_DEAL_PAYMENT = 'edit.deal.payment';
export const CLEAR_PAYMENTS_STORE = 'clear.payments.store';
export const PAYMENT_COST = 'payment.cost';
export const CLEAR_PAYMENT_COST = 'clear.payment.cost';
export const GET_PAYMENT_STATUSES = 'get.payment.statuses';
// zids
export const CREATE_NEW_ZID = 'create.new.zid';
export const UPDATE_ZID = 'update.zid';
export const GET_ZID_LIST_BY_DEAL_ID = 'get.zid.list.by.deal.id';
export const GET_ZID_LIST_BY_SITE_ID = 'get.zid.list.by.site.id';
export const ZID_COUNT = 'zid.count';
export const GET_ZID_INFO = 'get.zid.info';
export const DELETE_ZID = 'delete.zid';

// icons
export const GET_ICONS_LIST = 'get.icons.list';
export const TO_ICON_LIST = 'to.icon.list';
export const DELETE_FROM_ICON_LIST = 'delete.from.icon.list';

// communications
export const GET_COMMENTS = 'get.comments';
export const GET_ALL_COMMENTS = 'get.all.comments';
export const SEND_COMMENT = 'send.comment';
export const CREATE_NEW_TICKET = 'create.new.ticket';

// notifications
export const GET_NOTIFICATIONS = 'get.notifications';
export const GET_NOTIFICATIONS_TRIGGER = 'get.notifications.trigger';
export const READ_NOTIFICATION = 'read.notification';
export const READ_ALL_NOTIFICATION = 'read.all.notification';

// tickets
export const GET_TICKETS = 'get.tickets';
export const GET_COMPARE_TICKETS = 'get.compare.tickets';
export const GET_TICKETS_COUNT = 'get.tickets.count';
export const UPDATE_TICKET = 'update.ticket';
export const GET_TICKETS_STAT = 'get.tickets.stat';

// partner tickets
export const SEND_PARTNER_TICKET = 'send.partner.ticket';
export const GET_PARTNER_TICKETS = 'get.partner.tickets';
export const GET_PARTNER_CARD_TICKETS = 'get.partner.card.tickets';
export const ADD_PARTNER_TO_EXCEPTION = 'add.partner.to.exception';
export const DELETE_PARTNER_FROM_EXCEPTION = 'delete.partner.from.exception';
export const GET_EXCEPTION_PARTNERS = 'get.exception.partners';

// logs
export const GET_LOG = 'get.log';
export const GET_LOG_ACTIONS = 'get.log.actions';
export const GET_LOG_ENTITIES = 'get.log.entities';
export const GET_LOG_COUNT = 'get.log.count';

// contracts
export const GET_CONTRACTS = 'get.contracts';
export const GET_CONTRACTS_BY_DEAL = 'get.contracts.by.deal';
export const SEND_CONTRACT = 'send.contract';
export const GET_CONTRACT_STATUSES = 'get.contract.statuses';
export const RESERVE_CONTRACTS = 'reserve.contracts';

export const CREATE_CONTRACT = 'create.contract';
export const EDIT_CONTRACT = 'edit.contract';
export const CONTRACT_CHANGE_STATUS = 'contract.change.status';
export const CREATE_FIN_DATA = 'create.fin.daxta';
export const COPY_FIN_DATA = 'copy.fin.data';
export const ATTACH_CONTRACT_FILE = 'attach.contract.file';
export const BUTTONS_FOR_ROLE = 'buttons.for.role';

export const ATTACH_RESULT_FOR_CONTRACT = 'attach.result.for.contract';
export const SAVE_OFFLINE_FILE = 'save.offline.file';
export const OFFLINE_FILE_DELETE = 'offline.file.delete';
export const DELETE_CONTRACTS_FILE = 'delete.contracts.file';
export const DOWNLOAD_CONTRACTS_FILE = 'download.contracts.file';
export const DELETE_RESULT_FILE = 'delete.result.file';
export const DELETE_PAYMENT_CONTRACT = 'delete.payment.contract';
export const DELETE_CONTRACT = 'delete.contract';
export const DELETE_CONTRACT_FORMAT = 'delete.contract.format';

export const ATTACH_LEAD_FOR_CONTRACT = 'attach.lead.for.contract';
export const DETACHED_LEAD_FROM_CONTRACT = 'detached.lead.from.contract';

// titles
export const UPDATE_SMM_TITLES = 'update.smm.titles';
export const GET_SMM_TITLES = 'get.smm.titles';

// analytics
export const CREATE_NEW_ANALYTICS = 'create.new.analytics';

// articles
export const CREATE_ARTICLE = 'create.article';
export const UPDATE_ARTICLE = 'update.article';
export const GET_ARTICLE = 'get.article';
export const GET_ARTICLE_CATEGORIES = 'get.article.categories';
export const GET_ARTICLE_BY_CATEGORY = 'get.article.by.category';
export const UPLOAD_ARTICLE_IMAGE = 'upload.article.image';

// seo
export const GET_PROJECTS_LIST = 'get.projects.list';
export const GET_PROJECT_DATA = 'get.project.data';
export const GET_PROJECT_STATS = 'get.project.stats';
export const GET_PROJECT_POSITION = 'get.project.position';
export const GET_PROJECTS_LIST_BY_USER = 'get.projects.list.by.user';
export const DOWNLOAD_SEO_XLS = 'download.seo.xls';
export const DOWNLOAD_SEO_COMPETITORS_XLS = 'download.seo.competitors.xls';
export const GET_KEY_DATA = 'get.key.data';

// a_parser
export const GET_PRESETS = 'get.presets';
export const CREATE_PRESET = 'create.preset';
export const UPDATE_PRESET = 'update.preset';
export const DELETE_PRESET = 'delete.preset';

// tickets_retention
export const GET_RETENTION_ISSUES = 'get.retention.issues';
export const GET_RETENTION_STATUS_LIST = 'get.retention.status.list';
export const GET_RETENTION_STATUS_FLOW = 'get.retention.status.flow';
export const UPDATE_RETENTION_STATUS = 'get.retention.status';
export const GET_RETENTION_COUNTRY_LIST = 'get.retention.country.list';
export const SEARCH_RETENTION_SITE = 'search.retention.site';
export const RETENTION_MASS_ACTION = 'retention.mass.action';
export const RETENTION_UPLOAD_FILE = 'retention.upload.file';

export const GET_REASON_LIST = 'get.reason.list';
export const GET_LG_REPORT = 'get.lg.report';
export const COUNTRIES_LIST_BY_USER = 'country.list.by.user';
export const GET_RETENTION_PROJECTS = 'get.retention.ptojects';

// mirrors
export const ADD_NEW_MIRROR = 'add.new.mirror';
export const DELETE_MIRROR = 'delete.mirror';
export const CHOOSE_MAIN_MIRROR = 'choose.main.mirror';
export const GET_MIRRORS_LIST = 'get.mirrors.list';

// news
export const GET_NEWS_LIST = 'get.news.list';
export const CREATE_NEWS = 'create.news';
export const GET_USER_DATA = 'get.user.data';
export const DELETE_BUYINGSTAT_FILE = 'delete.buyingstat.file';

export const SET_INSTALL_COSTS = 'set.install.costs';
export const GET_INSTALL_COSTS = 'get.install.costs';
export const UPDATE_INSTALL_COSTS = 'update.install.costs';

export const GET_DEAL_INSTALLS = 'get.deal.installs';
export const GET_DEAL_SPENDS = 'get.deal.spends';
export const EDIT_CPI_SPENDS = 'edit.cpi.spends';

// reminders
export const UPDATE_USER_REMINDERS = 'update.user.reminders';
export const CREATE_USER_REMINDERS = 'create.user.reminders';
export const DELETE_USER_REMINDERS = 'delete.user.reminders';
export const GET_USER_REMINDERS = 'get.user.reminders';

// mobicash
export const GET_AGENTS_LIST = 'get.agents.list';
export const EDIT_AGENT = 'edit.agent';
export const CREATE_NEW_AGENT = 'create.new.agent';
export const GET_AGENT_CONDITIONS_LIST = 'get.agent.conditions.list';
export const CREATE_AGENT_CONDITION = 'create.agent.condition';
export const EDIT_AGENT_CONDITION = 'edit.agent.condition';
export const REJECT_AGENT = 'reject.agent';
export const DOWNLOAD_AGENTS_FILE = 'download.agents.file';
export const DOWNLOAD_CSV_FILE = 'download.csv.file';
export const DASHBOARD_TOP_GEO = 'dashboard.top.geo';
export const DASHBOARD_AGENTS_COUNT = 'dashboard.agents.count';
export const DASHBOARD_AGENTS_STATUSES = 'dashboard.agents.statuses';
export const DASHBOARD_AGENTS_GEOGRAPHY = 'dashboard.agents.geography';
export const MOBICASH_UPLOAD_TO_CSV = 'mobicash.upload.to.csv';

export const MOBICASH_UPLOAD_TO_CSV_TOP_GEO = 'mobicash.upload.to.csv.top.geo';
export const MOBICASH_UPLOAD_TO_CSV_STATUSES = 'mobicash.upload.to.csv.statuses';
export const MOBICASH_UPLOAD_TO_CSV_GEO = 'mobicash.upload.to.csv.geo';
export const MOBICASH_UPLOAD_TO_CSV_COUNT = 'mobicash.upload.to.csv.count';

// affiliate
export const GET_AFFILIATE_LIST = 'get.affiliate.list';
export const GET_AFFILIATE_TICKETS_LIST = 'get.affiliate.tickets.list';
export const GET_AFFILIATE_TICKET_CARD = 'get.affiliate.ticket.card';
export const GET_PROBLEMS_LIST = 'get.problems.list';
export const GET_TRIGGERS_LIST = 'get.triggers.list';
export const CREATE_AFFILIATE_TICKET = 'create.affiliate.ticket';
export const GET_AFFILIATE_MANAGERS = 'get.affiliate.managers';
export const DELETE_AFFILIATE_MANAGERS = 'delete.affiliate.managers';
export const ADD_MANAGER_TO_PARTNER = 'add.manager.to.partner';
export const CHOOSE_MAIN_MANAGER = 'choose.main.manager';
export const ADD_PROBLEM_TO_TICKET = 'add.problem.to.ticket';
export const DELETE_TICKET_PROBLEM = 'delete.ticket.problem';
export const SAVE_TICKET_COMMENT = 'save.ticket.comment';
export const SAVE_PROBLEM_COMMENT = 'save.problem.comment';
export const UPDATE_TICKET_PROBLEM = 'update.ticket.problem';

export const ADD_NEW_PROMOCODE = 'add.new.promocode';
export const GET_PROMOCODES = 'get.promocodes';
export const EDIT_PROMOCODE = 'edit.promocode';
export const DELETE_PROMOCODE = 'delete.promocode';

export const GET_MANUALS = 'get.manuals';
export const GET_MANUAL = 'get.manual';
export const APPOINT_MANAGERS = 'appoint.managers';

export const SET_PLACE_COST = 'set.place.cost';
export const UPDATE_PLACE_COST = 'update.place.cost';
export const CREATE_NEW_PLACEMENT = 'create.new.placement';
export const GET_DEAL_PLACEMENTS = 'get.deal.placements';
export const CREATE_PLACEMENT_PLACE = 'create.placement.place';
export const MASS_ADD_PLACEMENT_PLACE = 'mass.add.placement.place';

export const UPDATE_PLACEMENT_PLACE = 'update.placement.place';
export const CREATE_PLACEMENT_PLACE_SMM = 'create.placement.place.smm';

export const GET_PLACEMENTS_AVAILABLE_PAYMENTS = 'get.placements.available.payments';

export const DELETE_PLACEMENT_PLACE = 'delete.placement.place';
export const ATTACH_PLACE_SCREENSHOT = 'attach.place.screenshot';
export const DELETE_PLACE_SCREENSHOT = 'delete.place.screenshot';
export const UPDATE_PLACEMENT = 'update.placement';
export const DELETE_PLACEMENT = 'delete.placement';
export const DISTRIBUTE_PLACEMENT_COST = 'distribute.placement.cost';
export const DOWNLOAD_PLACE_FILE = 'download.place.file';
export const GET_LANDING_TYPES = 'get.landing.types';

// documentation report
export const GET_DOCUMENTATION_CONTRACT_REPORT = 'get.documentation.contract.report';
export const GET_DOCUMENTATION_PAYMENTS_REPORT_GENERAL = 'get.documentation.payments.report.general';
export const GET_DOCUMENTATION_PAYMENTS_REPORT_AVERAGE = 'get.documentation.payments.report.average';

// aff report
export const DOWNLOAD_AFFILIATE_STATS = 'download.affiliate.stats';
export const GET_AFFILIATE_REPORT = 'get.affiliate.report';

// messanger
export const GET_USER_CHATS = 'get.user.chats';
export const CREATE_NEW_CHAT = 'create.new.chat';
export const DELETE_USER_CHAT = 'delete.user.chat';
export const READ_USER_CHAT = 'read.user.chat';
export const UNREAD_USER_CHAT = 'unread.user.chat';
export const GET_USER_CHAT_COUNTER = 'get.user.chat.counter';
export const GET_MESSAGES_BY_CHAT_ID = 'get.messages.by.chat.id';
export const SEND_MESSAGE_TO_CHAT = 'send.message.to.chat';

// lead requests
export const DELETE_REQUEST_FILE = 'delete.request.file';
export const MAKE_LEAD_REQUEST = 'make.lead.request';
export const GET_MY_LEAD_REQUEST = 'get.my.lead.request';
export const DELETE_MY_LEAD_REQUEST = 'delete.my.lead.request';
export const GET_REQUEST_LIST_FOR_CONTROL = 'get.request.list.for.control';
export const GET_COLD_LIST_FOR_CONTROL = 'get.cold.list.for.control';
export const REQUEST_CHANGE_STATUS = 'request.change.status';
export const TRANSFER_COLD_LEADS = 'transfer.cold.leads';

export const GET_AUDITOR_RESPONSIBLE = 'get.auditor.responsible';
export const GET_AUDITOR_LIST = 'get.auditor.list';
export const UPDATE_AUDITOR_LIST = 'update.auditor.responsible';

export const GET_FILE_FORMATS_LIST = 'get.file.formats.list';
export const UPDATE_FILE_FORMATS_LIST = 'update.file.formats.list';
export const GET_SELECTED_FILE_FORMATS = 'get.selected.file.formats';
export const ATTACH_PARTNERS_FOR_MANAGERS = 'attach.partners.for.managers';
