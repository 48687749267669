import axios from 'axios';
import * as ACTIONS from '../actions';
import Vue from 'vue';

const vue = new Vue();

const state = {
    partners: {
        list: [],
        loader: true,
        products: [],
    },
    walletCurrencies: [],
    walletTypes: [],
    currentCurrency: 'USD'
};

const getters = {
    partnerList: state => state.partners.list,
    productsList: state => state.partners.products,
    partnerLoader: state => state.partners.loader,
    walletCurrencies: state => state.walletCurrencies,
    walletTypes: state => state.walletTypes,
    currentCurrency: state => state.currentCurrency,
};

const actions = {

    [ACTIONS.PARTNER_CREATE]: ({getters}, data) => {
        return new Promise((resolve) => {
            // создаем партнера
            return axios
                .put(getters.api.partners.partners, data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.PARTNER_LIST]: ({commit, getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.partners, {params: params})
                .then(res => {
                    commit('setPartnerList', res.data.data);
                    commit('setPartnerLoader', false);
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.PARTNER_PRODUCT]: ({commit, getters}) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.products.products)
                .then(res => {
                    commit('setPartnerProducts', res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });

    },

    [ACTIONS.PARTNER_SEARCH_LEAD]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.sites.sites + '/' + params.product_id + '/' + params.param_name + '/' + params.param_value)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.PARTNER_SITE_CREATE]: ({getters}, params) => {
        return new Promise((resolve) => {

            return axios
                .put(getters.api.partners.sites.sites, params)
                .then(() => {
                    vue.$notifySuccess({text: 'Sites added'});
                    resolve(true);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.PARTNER_INFO_GET]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.partners + '/' + params.partner_id, {params: params})
                .then(res => {
                    resolve(res.data.data);

                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });

    },

    [ACTIONS.PARTNER_EDIT]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.partners.partners + '/' + data.partner_id, data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.PARTNER_SITE_LIST]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.sites.byPartnerId + '/' + params.partner_id, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });

    },

    [ACTIONS.PARTNER_SITE_GET]: ({getters}, partner_site_id) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.sites.bySiteId + '/' + partner_site_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.PARTNER_SITE_UPDATE]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.partners.sites.sites + '/' + data.site_id, data)
                .then(() => {
                    resolve(true);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.PARTNER_SITE_DELETE]: ({getters}, partner_site_id) => {
        return new Promise((resolve) => {
            axios
                .delete(getters.api.partners.sites.sites + '/' + partner_site_id)
                .then(res => {
                    vue.$notifySuccess({text: 'Site deleted'});
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    // Wallets
    [ACTIONS.CURRENCIES_LIST]: ({getters}, params) => {

        return new Promise((resolve) => {
            axios
                .get(getters.api.partners.wallets.currencies, {params: params})
                .then(res => {
                    resolve(res.data.data);

                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });

    },

    [ACTIONS.WALLET_TYPES_LIST]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.wallets.types, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.WALLET_TYPES_LIST_ALL]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.wallets.types_list, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.WALLETS_LIST]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.wallets.partner + '/' + params.partner_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.WALLET_CREATE]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .put(getters.api.partners.wallets.wallets, data)
                .then(res => {
                    resolve(res);
                    vue.$notifySuccess({text: 'Wallet created'});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },

    [ACTIONS.WALLET_GET]: ({getters}, wallet_id) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.wallets.wallets + '/' + wallet_id)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.WALLET_SET_TIPS]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.partners.wallets.set_tips + '/' + data.wallet_type_id, data)
                .then(() => {
                    resolve(true);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.SET_CURRENT_CURRENCY]: ({commit}, currentCurrency) => {
        commit('setCurrentCurrency', currentCurrency);
    },

    [ACTIONS.CLEAR_CURRENT_CURRENCY]: ({commit}, currentCurrency) => {
        commit('clearCurrentCurrency', currentCurrency);
    },

    [ACTIONS.PARTNER_LIST_BY_PRODUCT]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.byProductId + '/' + params.product_id, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.PARTNER_COUNT_BY_PRODUCT]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.countByProduct + '/' + params.product_id, {params: params})
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.PARTNER_LIST_OFFLINE]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.offline, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.PARTNER_COUNT_OFFLINE]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.countOffline, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.PARTNER_OFFLINE_TYPES]: ({getters}) => {
        return new Promise((resolve) => {
            axios
                .get(getters.api.partners.typeList)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.PARTNER_SITE_STATS]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.rs_stat + '/' + params.partner_id, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.PARTNER_DOWNLOAD_RS_STATS]: ({getters}, data) => {
        return axios
            .get(getters.api.partners.rs_stat + '/' + data.partner_id + '/xls', {params: data})
            .then(res => {
                window.location.href = res.data.data.link;
            })
            .catch(err => {
                vue.$catchProceed(err);
            });
    },

    [ACTIONS.TRANSFER_PARTNERS]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.rs_stat, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.ATTACH_PARTNERS]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.partners.attach, data)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.DETACH_PARTNERS]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .put(getters.api.partners.detach, data)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_FREE_PARTNERS]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.free, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.GET_MY_PARTNERS]: ({getters}, params) => {
        return new Promise((resolve) => {
            return axios
                .get(getters.api.partners.my, {params: params})
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.CREATE_PARTNER_GROUP]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.partners.group, data)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.UPLOAD_PARTNER_COUNTRY]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.partners.upload, data.file, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    vue.$notifySuccess({text: res.data.data.message});
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.APPOINT_MANAGERS]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.partners.upload, data.file, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    resolve(res.data.data);
                    vue.$notifySuccess({text: res.data.data.message});
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },
    [ACTIONS.CHANGE_PARTNER_APPOINTED]: ({getters}, data) => {
        return new Promise((resolve) => {
            return axios
                .post(getters.api.partners.appointed + '/' + data.partner_id, data.file, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    vue.$notifySuccess({text: res.data.data.message});
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.DELETE_PARTNER_FILE]: ({getters}, foreign_id) => {
        return new Promise((resolve) => {
            axios
                .delete(getters.api.partners.deleteFile + '/' + foreign_id)
                .then(res => {
                    vue.$notifySuccess({text: 'File deleted'});
                    resolve(res);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                });
        });
    },

    [ACTIONS.ATTACH_PARTNERS_FOR_MANAGERS]: ({getters}, data) => {
        return new Promise((resolve, reject) => {
            return axios
                .post(getters.api.partners.takePartners, data)
                .then(res => {
                    vue.$notifySuccess({text: res.data.data.message});
                    resolve(res.data.data);
                })
                .catch(err => {
                    vue.$catchProceed(err);
                    reject(err.response);
                });
        });
    },

};

const mutations = {
    setPartnerList(state, payload) {
        state.partners.list = payload;
    },
    setPartnerLoader(state, payload) {
        state.partners.loader = payload;
    },
    setPartnerProducts(state, payload) {
        state.partners.products = payload;
    },
    setCurrenciesList(state, payload) {
        state.walletCurrencies = payload;
    },
    setWalletTypeList(state, payload) {
        state.walletTypes = payload;
    },
    setCurrentCurrency(state, payload) {
        state.currentCurrency = payload;
    },
    clearCurrentCurrency(state) {
        state.currentCurrency = 'USD'
    },

};

export default {
    state,
    getters,
    actions,
    mutations,
}
